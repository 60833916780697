import Swiper, { EffectCoverflow, Autoplay, Pagination, Navigation, SwiperOptions } from 'swiper';


class Home {
  private windowWidth: number = window.innerWidth;
  /**
   * Creates an instance of Index.
   */
  constructor() {
    this.mvSlider();
    new Swiper('.news-slider', {
      modules: [Pagination, Navigation],
      speed: 600,
      loop: false,
      grabCursor: true,
      slidesPerView: 'auto',
      navigation: {
        prevEl: '.prev-news',
        nextEl: '.next-news'
      }
    });
  }

  private mvSlider = (): void => {
    let slider: Swiper | null = null;
    slider = this.initMainVisual();
    window.addEventListener('resize', () => {
      if (this.windowWidth !== window.innerWidth && slider) {
        setTimeout(() => {
          if (!slider) return;
          slider.destroy(true, true);
          slider = null;
          slider = this.initMainVisual();
        }, 1000);
        //
      }
    });
  };

  private initMainVisual = () => {
    const commonOption: SwiperOptions = {
      modules: [Autoplay, Pagination],
      loop: true,
      speed: 700,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      },
      grabCursor: true,
      slidesPerView: 'auto',
      centeredSlides: false,
      pagination: {
        clickable: true,
        el: '.mv__sliderPaging'
      }
    };
    const settingDesktop: SwiperOptions = {
      ...commonOption,
      modules: [EffectCoverflow, Autoplay, Pagination],
      effect: 'coverflow',
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 200,
        modifier: 1,
        slideShadows: true
      }
    };
    if (this.windowWidth > 768) {
      return new Swiper('.mv__slider', settingDesktop);
    } else {
      return new Swiper('.mv__slider', commonOption);
    }
  };

}

window.addEventListener('DOMContentLoaded', () => {
  new Home();
})
